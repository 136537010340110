



































































































import { Component, Vue } from 'vue-property-decorator';
import { readAdminUsers } from '@/store/admin/getters';
import { dispatchDeleteCobroEfectivo, dispatchGetCobrosEfectivo } from '@/store/admin/actions';
import { dispatchCheckApiError } from '@/store/main/actions';

import { api } from '@/api';
import { getLocalToken } from '@/utils';
import VuePdfApp from 'vue-pdf-app';
import 'vue-pdf-app/dist/icons/main.css';
@Component({
  components: { VuePdfApp },
  filters: {
    formatDate(date = '') {
      if (!date) {
        return '';
      }
      const fecha = new Date(date);
      const dia = fecha.getDate().toString().padStart(2, '0');
      const mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
      const anio = fecha.getFullYear().toString();
      const horas = fecha.getHours().toString().padStart(2, '0');
      const minutos = fecha.getMinutes().toString().padStart(2, '0');
      const segundos = fecha.getSeconds().toString().padStart(2, '0');
      return `${dia}/${mes}/${anio} ${horas}:${minutos}:${segundos}`;
    }
  }

})
export default class CobroEfectivoList extends Vue {
  public showPdf = false;
  public pdfSrc = '';
  public showQrLayer = false;
  public loader = false;
  public alertMsg = false;
  public searchTxt = '';
  public allDocuments = [] as any;
  public overlay = false;
  public qrLoading = true;
  public qr = '';
  public headers = [
    {
      text: 'Nombre',
      sortable: true,
      value: 'nombre',
      align: 'left',
    },
    {
      text: 'Email',
      sortable: true,
      value: 'usuario.email',
      align: 'left',
    },
    {
      text: 'Cobro',
      sortable: true,
      value: 'codigo_inquilino',
      align: 'left',
    },
    {
      text: 'Importe',
      sortable: true,
      value: 'codigo_propietario',
      align: 'left',
    },
    {
      text: 'Fecha firma',
      sortable: true,
      value: 'firmado',
      align: 'left',
    },

    {
      text: 'Acciones',
      value: 'id',
    },
  ];
  get users() {
    return readAdminUsers(this.$store);
  }
  public async refrescar() {
    this.allDocuments = await dispatchGetCobrosEfectivo(this.$store);
  }
  public async mounted() {
    this.allDocuments = await dispatchGetCobrosEfectivo(this.$store);

  }
  public async showQr(id) {
    this.loader = true;
    this.overlay = true;
    const token = getLocalToken()!;
    try {
      const response = await api.getCobroEfectivoQr(token, id);
      if (response) {
        this.qr = response.data;
        this.loader = false;
        this.overlay = true;
        this.showQrLayer = true;
        this.showPdf = false;
      }
    } catch (error) {
      await dispatchCheckApiError(this.$store, error);
      this.loader = false;
      this.overlay = false;
      this.showQrLayer = false;
      this.showPdf = false;
    }




  }
  public async getPdf(id, tipo) {
    this.loader = true;
    this.overlay = true;
    const token = getLocalToken()!;
    try {
      let response;
      if (tipo === 1) {
        response = await api.getCobroEfectivoPdf(token, id);
      } else {
        response = await api.getCobroEfectivoPdfCert(token, id);
      }
      if (response) {
        const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
        const blobUrl = window.URL.createObjectURL(pdfBlob);
        this.pdfSrc = blobUrl;
        // const link = document.createElement('a')
        // link.href = blobUrl
        // link.setAttribute('download', "mi.pdf")
        // link.click();
        // link.remove();
        // URL.revokeObjectURL(blobUrl);
        this.loader = false;
        this.overlay = true;
        this.showPdf = true;
        this.showQrLayer = false;
      }
    } catch (error) {
      await dispatchCheckApiError(this.$store, error);
      this.loader = false;
      this.overlay = false;
      this.showPdf = false;
      this.showQrLayer = false;
    }

  }

  public async borrarUsuario(id) {
    await dispatchDeleteCobroEfectivo(this.$store, id);
    this.alertMsg = false;
    this.allDocuments = await dispatchGetCobrosEfectivo(this.$store);
  }
  public buscar() {
    if (this.searchTxt.length > 0) {
      let alldocs = [] as any;
      alldocs = this.users;
      const filtro = alldocs.filter((doc) => {
        return (
          this.stripVowelAccent(doc.nombre.toLowerCase()).indexOf(
            this.stripVowelAccent(this.searchTxt.toLowerCase()),
          ) !== -1 ||
          this.stripVowelAccent(doc.email.toLowerCase()).indexOf(
            this.stripVowelAccent(this.searchTxt.toLowerCase()),
          ) !== -1 ||
          this.stripVowelAccent(doc.codigo_propietario?.toLowerCase()).indexOf(
            this.stripVowelAccent(this.searchTxt.toLowerCase()),
          ) !== -1 ||
          this.stripVowelAccent(doc.codigo_inquilino?.toLowerCase()).indexOf(
            this.stripVowelAccent(this.searchTxt.toLowerCase()),
          ) !== -1
        );
      });
      this.allDocuments = filtro;
    } else {
      this.allDocuments = this.users;
    }
  }
  public stripVowelAccent(str) {
    const rExps = [
      { re: /[\xC0-\xC6]/g, ch: 'A' },
      { re: /[\xE0-\xE6]/g, ch: 'a' },
      { re: /[\xC8-\xCB]/g, ch: 'E' },
      { re: /[\xE8-\xEB]/g, ch: 'e' },
      { re: /[\xCC-\xCF]/g, ch: 'I' },
      { re: /[\xEC-\xEF]/g, ch: 'i' },
      { re: /[\xD2-\xD6]/g, ch: 'O' },
      { re: /[\xF2-\xF6]/g, ch: 'o' },
      { re: /[\xD9-\xDC]/g, ch: 'U' },
      { re: /[\xF9-\xFC]/g, ch: 'u' },
      { re: /[\xD1]/g, ch: 'N' },
      { re: /[\xF1]/g, ch: 'n' },
    ];
    if (str) {
      for (let i = 0, len = rExps.length; i < len; i++) {
        str = str.replace(rExps[i].re, rExps[i].ch);
      }
    } else {
      return '';
    }
    return str;
  }
}
